import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import transferLocationsSaga from './transferLocations/saga';
import locationGroupsSaga from './locationGroups/saga';
import layoutSaga from './layout/saga';

export default function* rootSaga() {
    yield all([authSaga(), transferLocationsSaga(), locationGroupsSaga(), layoutSaga()]);
}
