import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import TransferLocations from './transferLocations/reducers';
import LocationGroups from './locationGroups/reducers';
import Layout from './layout/reducers';
import PageTitle from './pageTitle/reducers';

export default combineReducers({
    Auth,
    TransferLocations,
    LocationGroups,
    Layout,
    PageTitle,
});
